import {Serializer, FormItemModel, FormModel} from '@matchsource/api-utils';
import {forEachInTree} from '@matchsource/utils';
import {formatDate} from '@matchsource/date';

export const cordIdmsSerializer: Serializer<FormModel, FormModel> = {
  fromDTO(input: FormModel): FormModel {
    const mappedForm = createEmptyForm(input);
    let currentGroup: FormItemModel = null;

    input.children.forEach(child => {
      currentGroup = addItem(child, currentGroup, mappedForm);
    });
    return mappedForm;
  },
};

const createEmptyForm = (form: FormModel): FormModel => {
  return {
    formType: form.formType,
    children: [],
    name: form.name || null,
    date: form.date ? formatDate(form.date) : null,
  };
};

const createEmptyFormGroup = (data: FormItemModel): FormItemModel => {
  const newGroup: FormItemModel = {
    type: 'GROUP',
    children: [],
  };
  if (data) {
    newGroup.title = data.title;
  }
  return newGroup;
};

const addGroupItem = (form: FormModel, data: FormItemModel = {}): FormItemModel => {
  const children = data?.children || [];
  const group = createEmptyFormGroup(data);
  children.forEach(item => addItem(item, group, form));
  form.children.push(group);
  return group;
};

const addQuestionItem = (currentGroup: FormItemModel, form: FormModel, data: FormItemModel = {}): FormItemModel => {
  forEachInTree(data, (item: FormItemModel, key: string, parent: FormItemModel) => {
    if (key === 'date') {
      parent[key] = formatDate(item as string);
    } else if (key === 'answerType' && parent.answerType === 'DATE') {
      parent.answer = formatDate(`${parent.answer}`);
    }
  });

  const group = currentGroup || addGroupItem(form);
  group.children.push(data);
  return group;
};

const addItem = (data: FormItemModel, currentGroup: FormItemModel, form: FormModel): FormItemModel => {
  return data.type === 'GROUP' ? addGroupItem(form, data) : addQuestionItem(currentGroup, form, data);
};
