import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CordLookupDataSetModel, CordSearchParams, GetRbcAndPlasmaReducedResponseModel} from '@matchsource/models/cord';
import {
  LookupControllerService,
  UnitReportControllerService,
  CordControllerService,
  Cord,
} from '@matchsource/api-generated/subject';

import {cordIdmsSerializer} from './cord-idms-serializer';
import {FormModel} from '@matchsource/api-utils';
import {EthnicityRacesModel} from '@matchsource/models/source';
import {mapEthnicityRaceDtoToModel} from '@matchsource/api/source-base';
import {cordLookupSetSerializer} from './cord-lookup-set.serializer';
import {cordProcessingProcedureResponseSerializer} from './cord-processing-procedure-response.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import flow from 'lodash-es/flow';
import {uniq} from '@matchsource/utils';
import {HttpContext} from '@angular/common/http';
import {skipSpinner, useSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class CordApiService {
  constructor(
    private readonly unitReportService: UnitReportControllerService,
    private readonly cordControllerService: CordControllerService,
    private readonly lookupControllerService: LookupControllerService
  ) {}

  private static getLoadingCordCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingCordData);
  }

  getCord(params: {guid: string}): Observable<Cord> {
    const customErrorHandlingContext = CordApiService.getLoadingCordCustomErrorHandling();

    return this.cordControllerService.getCordByGuid(params, {context: customErrorHandlingContext()});
  }

  getCordsByGuids(
    cordIds: MsApp.Guid[],
    {ignoreError = false, silent = false}: {ignoreError?: boolean; silent?: boolean} = {}
  ): Observable<Cord[]> {
    if (cordIds.length === 0) {
      return of([]);
    }

    const context = useSpinner(silent)();

    return this.cordControllerService.getCordsByGuids({body: uniq(cordIds), ignoreError}, {context});
  }

  getRbcAndPlasmaReduced(cordIds: MsApp.Guid[]): Promise<GetRbcAndPlasmaReducedResponseModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingCordData);

    return firstValueFrom(
      this.cordControllerService
        .getProcessingProcedure(
          {
            body: cordIds,
          },
          {
            context: context(),
          }
        )
        .pipe(map(procedures => cordProcessingProcedureResponseSerializer.fromDTO(procedures)))
    );
  }

  search({searchTerm = ''}: Partial<CordSearchParams> = {}): Observable<CordLookupDataSetModel> {
    const customErrorHandlingContext = CordApiService.getLoadingCordCustomErrorHandling();

    return this.lookupControllerService
      .lookupCordById({cordId: searchTerm}, {context: customErrorHandlingContext()})
      .pipe(map(input => cordLookupSetSerializer.fromDTO(input)));
  }

  requestUnitReport(recipientGuid: MsApp.Guid, cordGuids: MsApp.Guid[]): Observable<boolean> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.PreparingUnitReports);

    return this.unitReportService
      .report$Response(
        {
          body: {
            recipientGuid,
            cordGuids,
          },
        },
        {
          context: context(),
        }
      )
      .pipe(map(({status}) => status < 400 || status >= 600));
  }

  getTestResults(params: {guid: string; maternal?: boolean}): Observable<FormModel[]> {
    const customErrorHandlingContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingCordData);

    return this.cordControllerService
      .getCordIdms(params, {context: customErrorHandlingContext()})
      .pipe(map(forms => forms.map(form => cordIdmsSerializer.fromDTO(form))));
  }

  getAdditionalTestsResults(params: {guid: string}): Observable<FormModel[]> {
    const customErrorHandlingContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingCordData);

    return this.cordControllerService
      .getAdditionalTestForms(params, {context: customErrorHandlingContext()})
      .pipe(map(forms => forms.map(form => cordIdmsSerializer.fromDTO(form))));
  }

  getCordEthnicityRace(cordId: MsApp.Guid, silent = false): Observable<EthnicityRacesModel> {
    const customErrorHandlingContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingCordData);
    const context = flow(skipSpinner, customErrorHandlingContext);
    return this.cordControllerService
      .getCordEthnicityRace(
        {
          guid: cordId,
        },
        {
          context: silent ? context() : customErrorHandlingContext(),
        }
      )
      .pipe(map(sourceEthnicityRaces => mapEthnicityRaceDtoToModel(sourceEthnicityRaces)));
  }
}
