import {ProcessingProcedure} from '@matchsource/api-generated/subject';
import {Serializer} from '@matchsource/api-utils';
import {CordRbcAndPlasmaReducedModel, GetRbcAndPlasmaReducedResponseModel} from '@matchsource/models/cord';
import {cordProcessingProcedureSerializer} from './cord-processing-procedure.serializer';

export const cordProcessingProcedureResponseSerializer: Serializer<
  GetRbcAndPlasmaReducedResponseModel,
  MsApp.Dictionary<ProcessingProcedure>
> = {
  fromDTO(input: MsApp.Dictionary<ProcessingProcedure>): GetRbcAndPlasmaReducedResponseModel {
    input = input || {};
    const model: GetRbcAndPlasmaReducedResponseModel = {} as MsApp.Dictionary<CordRbcAndPlasmaReducedModel>;

    Object.keys(input).forEach(key => {
      model[key] = cordProcessingProcedureSerializer.fromDTO(input[key]);
    });

    return model;
  },
};
