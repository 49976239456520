import {Serializer} from '@matchsource/api-utils';
import {CordLookup} from '@matchsource/api-generated/subject';
import {CordLookupModel} from '@matchsource/models/cord';
import {SOURCE_PRODUCT_TYPE_LIST_MAP} from '@matchsource/models/source';

export const cordLookupSerializer: Serializer<CordLookupModel, CordLookup> = {
  fromDTO(input: CordLookup): CordLookupModel {
    return {
      guid: input.guid,
      nmdpId: input.nmdpId,
      localId: input.localId,
      bpGuid: input.bpGuid,
      emdisId: input.emdisId,
      status: input.status,
      displayId: input.displayId,
      categoryCode: SOURCE_PRODUCT_TYPE_LIST_MAP[input.categoryCode || ''],
    };
  },
};
