import {ProcessingProcedure} from '@matchsource/api-generated/subject';
import {Serializer} from '@matchsource/api-utils';
import {CordRbcAndPlasmaReducedModel} from '@matchsource/models/cord';

export const cordProcessingProcedureSerializer: Serializer<CordRbcAndPlasmaReducedModel, ProcessingProcedure> = {
  fromDTO(input: ProcessingProcedure): CordRbcAndPlasmaReducedModel {
    return {
      bagType: input.bagType,
      fractionCount: input.fractionCount,
      numberOfCordBloodUnitBags: input.numberOfCordBloodUnitBags
        ? Number.parseInt(input.numberOfCordBloodUnitBags, 10)
        : undefined,
      plasmaReduced: input.plasmaReduced,
      processingMethodType: input.processingMethodType,
      processingMethods: input.processingMethods,
      productModifications: input.productModifications,
      rbcReduced: input.rbcReduced,
    };
  },
};
