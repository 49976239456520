import {Serializer} from '@matchsource/api-utils';
import {createPaginatedataSetFromArray} from '@matchsource/api-utils';
import {CordLookupDataSetModel, CordLookupModel} from '@matchsource/models/cord';
import {CordLookup} from '@matchsource/api-generated/subject';
import {cordLookupSerializer} from './cord-lookup.serializer';

export const cordLookupSetSerializer: Serializer<CordLookupDataSetModel, CordLookup[]> = {
  fromDTO(input: CordLookup[]): CordLookupDataSetModel {
    return createPaginatedataSetFromArray<CordLookupModel, CordLookup>(input, cordLookupSerializer);
  },
};
